import React, { useState, useRef, useEffect  } from "react";
import "./../BuyerForm/BuyerFormStyle.css";
import { Link } from "react-router-dom";


function BuyerForm() {
  const checkboxRef = useRef(null);
  
const [formData, setFormData] = useState({
first_name:'',
last_name:'',
email:'',
phone_num:'',
address:'', 

});  

const handleChange=(e)=>{
const {name, value} = e.target;
setFormData({
  ...formData,
  [name]:value
});
};       

  const handleSubmit = (e) => {
    e.preventDefault(); 
    fetch('https://workflow-automation.podio.com/catch/qj3p1550563y5w4', {
      mode: 'no-cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'         
      },   
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        setFormData({
          first_name: '',  
          last_name: '',
          email: '',
          phone_num: '',
          address: ''
        });
        checkboxRef.current.checked = false;
        
  
        alert('Form submitted successfully!');
      })
      .catch(error => {
        console.error('Error:', error);
        setFormData({
          first_name: '',
          last_name: '',
          email: '',
          phone_num: '',
          address: ''
        });
        checkboxRef.current.checked = false;
       
       

      });
  };
  useEffect(() => {
    // Load reCAPTCHA when component mounts
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);                        

    return () => {
      // Clean up reCAPTCHA script when component unmounts
      document.head.removeChild(script);
    };
  }, []);       
  return (
    <div className="main_img_div">
      <div className="div_for_centering">
        <div className="buyer_form_content">     
          <div className="america_text">
            AMERICA’S MOST <br />
            <span className="trusted_text">TRUSTED</span> 
            <br />
            HOME BUYER
          </div>
          <div className="below_america_text">                                         
            Schedule your free consultation today to <br />
            find out how we can help you
          </div>

          <div className="blurry_card">
            <div className="card_content">
              <div className="blurry_card_heading">
                {" "}
                Get Your Fair Offer In 30 Minutes Or Less!
              </div>

              <div className="form_div">
              <form
              id="form"  
              className="actual_form"
              onSubmit={handleSubmit}
              action="#"
            >
             
            
                  <div className="form_row">
                    <input
                   
                      className="my_form_inputs"
                      type="text"
                      placeholder=" First Name*"
                      name="first_name"
                      value={formData.first_name}
                     onChange={handleChange}
                      required
                    />

                    <input
                      className="my_form_inputs"
                      type="text"
                      placeholder=" Last Name*"
                      name="last_name"
                      value={formData.last_name}
                     onChange={handleChange}
                      required
                    />
                    <br />
                  </div>

                  <div className="form_row">
                    <input
                      className="my_form_inputs"
                      type="email"
                      required
                      placeholder=" Email*"
                      name="email"
                      value={formData.email}
                     onChange={handleChange}
                    />
                    <input
                      className="my_form_inputs"
                      type="phone"
                      placeholder=" Phone Number*"
                      name="phone_num"
                      value={formData.phone_num}
                     onChange={handleChange}
                      required
                    />
                  </div>
  
                  <div className="form_row">
                    <input
                      className="my_form_inputs"
                      type="text"
                      placeholder=" Address*"
                      name="address"
                      value={formData.address}
                     onChange={handleChange}
                      required
                    />
                    <br />
                  </div>
                  <div className="address_checkbox_container1">
                    <label>
                      <input type="checkbox" className="checkboxx" required ref={checkboxRef} />
                      <span className="checkbo">Yes, I Agree</span>
                  <p className="checkbo">By submitting this form and signing up for texts, you consent to receive marketing text messages from Seven Pines Investment at the number provided. Consent is not a condition of purchase. Msg & data rates may apply. Unsubscribe at any time by replying STOP or clicking the unsubscribe link. Read our Privacy Policy & Terms & Conditions. </p>    
                    </label>
                  </div>
                  <div
                  className="g-recaptcha"
                  data-sitekey="6LdQBjooAAAAAK2Tg5wEE-w3hTBuxvbPEoiwf6wp"
               
                  data-size="normal" 
                  style={{ maxWidth: '100%' }}
                ></div>
                
                
                  <div className="text-danger" id="recaptchaError"></div>
                  <div className="btn_class_center">
                    <button className="my_form_button " type="submit">
                      Get My Offer Now!
                    </button>
                  </div>

                  <div>
                  <p className="privacy_pol">Provided information is secured and <span><a href='privacypolicy' className="pol">Privacy Policy</a> </span> applied.</p>
                  </div>
                </form>
              </div>
            </div>            
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default BuyerForm;