import React, { useState } from "react";
import "./../Questions/QuestionsStyle.css";
import plus_icon from "./../../Assets/plus_iconn.png";
import minus_icon from "./../../Assets/minus_iconn.png";

function Questions() {
  const [activeIndex, setActiveIndex] = useState(-1); // State to keep track of the active panel index

  // Function to toggle the active panel
  const togglePanel = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const accordionItems = [
    {
      heading: "How do I know if you're a legitimate company?",
      content: "We are a legitimate company with a long track record of buying homes in Georgia. We have a team of experienced professionals who can help you sell your home rapidly and for a fair price. We are also transparent about our fees and offer a no-obligation consultation.We are a legitimate company with a long track record of buying homes in Georgia. We have a team of experienced professionals who can help you sell your home rapidly and for a fair price. We are also transparent about our fees and offer a no-obligation consultation.",
    },
    {
      heading: "What kind of homes do you buy?",
      content: "We buy all types of homes in Georgia, including houses, condos, townhouses, and even mobile homes. We don't care about the condition of your home, as long as it's not a total wreck. We also don't care about the reason you're selling, whether you're facing foreclosure, moving, or just don't want to deal with the hassle of selling your home on the market.",
    },
    {
      heading: "How much will you pay for my home?",
      content: "We'll make you a fair offer based on the current market value of your home. We'll take into account the condition of your home, the location, and the current market conditions. We'll also be upfront about our fees, so you'll know exactly how much you'll net from the sale.",
    },
    {
      heading: "How rapidly can you close on the sale?",
      content: "We can close on the sale of your home as rapidly as 7 days, or whenever it's convenient for you. We understand that you may need to move rapidly, and we'll work with you to make the sale happen as rapidly as possible.",
    },
    {
      heading: "Can you help with inherited or probate properties?",
      content: "Yes, we can assist with inherited or probate properties. We understand the unique challenges associated with these types of properties and can guide you through the process while offering a fair offer price.",
    },
    
  ];

  return (
    <div className="main_question_colored">
      <div className="questions_content">
        <div className="question_heading">
          <strong>Questions?</strong> We Have Answers.
        </div>

        {accordionItems?.map((data, index) => (
          <div
            class="accordion"
            id="accordionPanelsStayOpenExample"
            key={index}
          >
            <>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  id={`panelsStayOpen-heading-${index}`}
                >
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#panelsStayOpen-collapse-${index}`}
                    aria-expanded={activeIndex === index ? "true" : "false"}
                    aria-controls={`panelsStayOpen-collapse-${index}`}
                    onClick={() => togglePanel(index)}
                  >
                    <div className="row width_row_accordian">
                      <div className="col-1">
                        <img
                          className="plus_img"
                          src={activeIndex === index ? minus_icon : plus_icon}
                          alt={activeIndex === index ? "Minus" : "Plus"}
                        />
                      </div>
                      <div className="col-10">
                        <p className="btn_text">{data.heading}</p>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id={`panelsStayOpen-collapse-${index}`}
                  class={`accordion-collapse collapse ${
                    activeIndex === index ? "show" : ""
                  }`}
                  aria-labelledby={`panelsStayOpen-heading-${index}`}
                >
                  <div class="accordion-body">{data.content}</div>
                </div>
              </div>
            </>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Questions;
