import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom'; // Corrected import statement
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( // Corrected usage of 'root.render'
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
