import React from 'react'
import "./../Feedback/FeedbackStyle.css"
import stars from "./../../Assets/stars.png"
import feedbackk from "./../../Assets/feedbackk.png"
import feedback from "./../../Assets/feedback.png"
import feedback1 from "./../../Assets/feedback1.png"
import feedback2 from "./../../Assets/feedback2.png"
import feedback3 from "./../../Assets/feedback3.png"
import feedback4 from "./../../Assets/feedback4.png"


function Feedback() {
  return (
    <div className='main_feedback'>
      <div className='feedback_content'  >
            <div className='feedback_headings' >WHAT OUR <strong>CLIENTS</strong> SAY</div>
            <div className='feedback_flex' >

                <div className='feedback_flex_col' >
                    <div className='feedback' >
                        <div className='client_name' >Thomas Christopher: </div>
                        
                        <div><img className='star_img' src={stars}  /></div>
                        <div><img className='feedbackk_img' src={feedback}  /></div>
                    </div>

                    <div className='feedback' >
                        <div className='client_name' >Joseph Richard:</div>
                        
                        <div><img className='star_img' src={stars}  /></div>
                        <div><img className='feedbackk_img' src={feedback2}  /></div>
                    </div>
                </div>

                <div className='feedback_flex_col' >
                    <div className='feedback' >
                        <div className='client_name' >David William:</div>
                        
                        <div><img className='star_img' src={stars}  /></div>
                        <div><img className='feedbackk_img' src={feedback1}  /></div>
                    </div>

                    <div className='feedback' >
                        <div className='client_name' >James Peterson:</div>
                        
                        <div><img className='star_img' src={stars}  /></div>
                        <div><img className='feedbackk_img' src={feedback4}  /></div>
                    </div>
                </div>

            </div>
      </div>
    </div>
  )
}

export default Feedback
