import React, {useState} from 'react'


import "../Footer/Footer.css"
import fb from "../../Assets/fb.png"
import insta from "../../Assets/insta.png"
import twitter from "../../Assets/twitter.png"
import lnkdn from "../../Assets/lnkdn.png"
import { Link } from 'react-router-dom'
import Privacy from '../Privacy/Privacy'
function Footer() {
 

  const [email, setEmail] = useState('');
  function handleSubscribe() {
    // Make an HTTP request to your webhook URL
    fetch('https://workflow-automation.podio.com/catch/qj3p1550563y5w4', {
      mode: 'no-cors',

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then(response => response.json())
    .then(data => {
      setEmail('');
      console.log('Success:', data);
    })
    .catch(error => {
      setEmail('');
      console.error('Error:', error);
    });
  }
  
  return (
    <div className='back'>
      <div className='container'>
      <div className='row pt-5 pb-5'>
      <div className='col-4'>
      <p className='head_foot'>Overview<span className='teet1'></span></p>
      <p className='head_cont'>Seven Pines Investment buys homes in as-is condition. Our
      stress-free process is proven. We work one-on-one
      with each homeowner to create a unique custom
      solution; despite the circumstances.</p>
      <div className='row'>
      <span className='col-2'><img src={fb}/></span>
      <div className='col-2'><img src={insta}/></div>
      <div className='col-2'><img src={twitter}/></div>
      <div className='col-2'><img src={lnkdn}/></div>
      </div>
      </div>
      <div className='col-2'>
      
      <p className='head_foot'>Direct Links<span className='teet'></span></p>
      <li className='listdata'>Home</li>
     <li className='listdata'>How it Works</li>
     <li className='listdata'>About Us</li>
     <li className='listdata'>Blogs</li>
    <li className='listdata'> <a href='privacypolicy' className='listdata'>Privacy Policy</a></li>
     
      
   
      </div>
      <div className='col-2'>
      <p className='head_foot'><span className='teet'></span>Our Support</p>
      <p className='head_cont'>Contact Us</p>
      </div>
      <div className='col-4'>
      <p className='head_foot'><span className='teet1'></span>Newsletter</p>
      <p className='head_cont'>Subscribe Our Newsletter & Get More</p>
      
      <div ><input 
      type='email' 
      placeholder='info@gmail.com' 
      className='input-subs mb-3'
      value={email}
      onChange={(e) => setEmail(e.target.value)} // Update the email state
    /></div>
    <button 
    className='btn-subcribe pl-3 pr-3'
    onClick={handleSubscribe} // Call handleSubscribe on button click
  >Subscribe Now</button>
      </div>
      </div>
      
      </div>
      <div className='back-col'>
      <div className='container'>
      <div className='test'>  Copyright ©  <a href='https://stopshoprei.com/' target='_blank' className='stopshop_link'><span>StopShopREI LLC </span></a><span>| All Rights Reseved.</span></div>
    
      
      </div>
      </div>
    </div>
  )
}

export default Footer
