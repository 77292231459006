import React from 'react'
import "../Privacy/Privacy.css"
import phone_icon from "./../../Assets/phone_icon.png"
import navbar_icon from "./../../Assets/sevenpinelogo.png"
import Navbar from '../Navbar/Navbar'
import Home from '../Home/Home'
import { Link } from 'react-router-dom'
import Footer from '../Footer/Footer'
function Privacy() {
  return (
    <div>
    <div className='nav_bac'>
    <div className="main_div_navbar">
    <nav class="navbar navbar-expand-lg ">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
        <Link to='/'>
          <img class="navbar-brand company_logo_image" href="#" src={navbar_icon} />
          </Link>
        </a>

        <button
          class="navbar-toggler btn_toggle"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 navbar_text_links">
            <li class="nav-item">
            <Link className='link_color' to='/'>
              <a
                class="nav-link why_review_faq_text"
                aria-current="page"
                href="#id_steps"
              >
                How It Works
              </a>
              </Link>
            </li>
            <li class="nav-item">
            <Link className='link_color' to='/'>
              <a class="nav-link why_review_faq_text" href="#id_comparison">
                Why US
              </a>
              </Link>
            </li>
            <li class="nav-item">
            <Link className='link_color' to='/'>
              <a class="nav-link why_review_faq_text" href="#id_testimonial">
                Reviews
              </a>
              </Link>
            </li>
            <li class="nav-item">
            <Link className='link_color' to='/'>
              <a class="nav-link why_review_faq_text" href="#id_questions">
                FAQS
              </a>
              </Link>
            </li>
          </ul>

          
            <div className=" button_navbar_end row">
              <div className="phone_div col-lg-2
              col-md-2 col-2">
                <img src={phone_icon} className="phone_logo_navbar" />
              </div>

              <div type="button" className="my_top_blue_btn col-lg-10 col-md-10 col-10">
              +4045798662
              </div>
            </div>
          

        </div>
      </div>
    </nav>
  </div>
    </div>
      <div className='container main_pol'>
      <h2 className='privacy_head'>Privacy Policy</h2>
      <h3 className='content_head'>Our Commitment to Privacy</h3>
      <p className='content_per'> <b>Seven Pines Investment</b> is committed to protecting the privacy and security of your personal information. This Privacy Policy outlines the types of information we collect from you, how we use and protect that information, and your rights regarding your personal data. By using our website and services, you consent to the practices described in this policy.  </p> 
      <h3 className='content_head'>What information do we collect?</h3>
       <p className='content_per'> We collect information from you when you subscribe to our newsletter or marketing updates, fill out a form or submit property information.</p>

    <p className='content_per'> When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address or phone number. We transfer information about you if  Seven Pines Investment is acquired by or merged with another company. In this event,  Seven Pines Investment will notify you before information is transferred and becomes subject to a different privacy policy </p> 
    <p className='content_per'>Any of the information we collect from you may be used in one of the following ways:</p>
   
    <ul>
      <li>
        To process transactions:
        <p className='content_per'> Your information will be used in completion of buying process, property assessment and property documentation.</p>
      </li>
      <li>
      	To communicate with you: 
        <p className='content_per'>The contact information (Phone number and e-mail) you provide may be used to send you information, respond to inquiries, and/or other requests or questions</p>
      </li>
    </ul>
    <h5 className='content_head'>Data Security
    </h5>
       <p className='content_per'>We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is entirely secure, and we cannot guarantee absolute security.

       </p>
  
  
       <h5 className='content_head'>Childrens Online Privacy Protection Act Compliance
       </h5>
       <p className='content_per'>We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or older.

       </p>
      
  
  
     
<h5 className='content_head'>Opt-In Information and Third-Party Sharing

    </h5>
    <p className='content_per'>We respect your privacy choices, especially concerning the sharing of your personal information. Any information you provide through opt-in consent will not be shared with third parties for marketing or promotional purposes.

    </p>
    <h5 className='content_head'>Opt-In for Marketing Text Messages

    </h5>
    <p className='content_per'>By submitting the contact form and signing up for texts, you consent to receive marketing text messages from Seven Pines Investment at the number +404-579-8662. Consent is not a condition of purchase. Message and data rates may apply. You can unsubscribe at any time by replying STOP to the received SMS (texts) or clicking the unsubscribe link (where available) in the marketing text messages.

    </p>



<h5 className='content_head'>Your Rights</h5>
<p className='content_per'>You have the right to access, update, correct, and delete your personal information we hold. If you wish to exercise any of these rights, please contact us at Sheena.murry@sevenpinesinvest.com or you can call us on +404-579-8662</p>

<h5 className='content_head'>Changes to this Privacy Policy</h5>
<p className='content_per'>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The updated policy will be posted on our website, and the effective date will be revised accordingly.</p>

<h5 className='content_head'>Contacting Us</h5>

<p className='content_per'>If there are any questions regarding this privacy policy you may contact us with the information on our contact page.</p>

<p><b>Seven Pines Investment</b></p>
   
    

    </div>
    <Footer/>
    </div>
  )
}

export default Privacy
