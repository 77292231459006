import React from 'react'
import "./../Convenience/ConvenienceStyle.css"
import Privacy from '../Privacy/Privacy'
import { Link } from "react-router-dom";
function Convenience() {
  
  return (
  <div className='convenience_full_width'>
      <div className='main_convenience_pic'>
      <div className='convenience_text' >Close At Your<strong> Convenience</strong></div>
      <div className='below_convenience' >Selling your home has never been so easy!</div>
      <button  className='ofr_btn' >Get My Offer Now!</button>
      <div className='call_text' >Or Call Us For FREE <strong>+404-579-8662</strong></div>

      
      
     


      </div>
     
  </div>
  )
}

export default Convenience
