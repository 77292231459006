import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes, Redirect } from 'react-router-dom';

import './App.css';
import Home from './Components/Home/Home';
import Privacy from './Components/Privacy/Privacy';

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route path="/privacypolicy" element={<Privacy/>} />
      
    </Routes>
  );
}

export default App;
